<!-- 用户上传视频暂存 -->
<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>内容管理</el-breadcrumb-item>
            <el-breadcrumb-item>用户上传视频</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <p style="float: left;margin: 0;">选择类型：</p>
            <el-select filterable v-model="videotype" clearable @clear="clearvideotype" placeholder="请选择类型" style="margin-right: 20px;float: left;width: 15%;">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" style="margin-left:20px" @click="getdata(1)">查询</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column prop="cust_id" label="用户ID"></el-table-column>
            <el-table-column label="标题" prop="title"></el-table-column>
            <el-table-column label="简介" prop="des"></el-table-column>
            <el-table-column label="类型" prop="type">
                <template slot-scope="scope">
                    <span v-if="scope.row.type == 1">反馈视频</span>
                    <span v-else>寻医视频</span>
                </template>
            </el-table-column>
            <el-table-column label="是否处理" prop="is_add">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_add == 0">否</span>
                    <span v-else>是</span>
                </template>
            </el-table-column>
            <el-table-column width="280">
                <template slot-scope="scope">
                    <div class="opt doc">
                        <el-button size="mini" type="success" icon="el-icon-caret-right" @click="edit('bj',scope.row)">视频预览</el-button>
                        <!-- <a <i class="el-icon-video-play" style="font-size: 24px;"></i></a> -->
                        <!-- v-if="scope.row.is_add == 0"  -->
                        <el-button size="mini" type="primary" icon="el-icon-delete" @click="del(scope.row,scope.$index)">删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
        <userVideotc :showtc.sync='showtc' :msgid='msgid' :userid="userid" :videotype="videotype"></userVideotc>
    </div>
</template>
<script>
    import userVideotc from '../components/userVideotc.vue'
    export default {
        components: {
            userVideotc
        },
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                addinhzbl: false,
                radio: '0',
                addhzbl: {},
                showtc: false,
                msgid: '',
                videotype:'',
                options:[
                    {id:1,name:'反馈视频'},
                    {id:2,name:'寻医视频'},
                ],
                userid:'',
                videotype:''
            }
        },
        mounted() {
            this.getdata(1);
        },
        methods: {
            clearvideotype(){
                this.getdata(1);
            },
            getdata(page) {
                this.axios.get('/video/get_gu_video_queue?page=' + page + "&size=" + this.PageSize+"&type="+this.videotype)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.list = res.data.result;
                            this.totalCount = res.data.count;
                        }
                    })
                    .catch(res => {
                        console.log(res)
                    })
            },
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getdata(currentPage);
            },
            //编辑
            edit(type, row) {
                // this.$router.push({ path: '/userVideoDetail', query: { id: row.id, title: row.title } });
                this.showtc = true;
                this.msgid = row.id;
                this.userid = row.cust_id;
                this.videotype = row.type;
            },
            //删除
            del(row, index) {
                this.$confirm('此操作将永久删除该视频, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/video/delete_gu_video_queue", this.qs.stringify({ id: row.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
        },
    }
</script>
<style>
    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
        float: left;
    }

    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }
</style>