<!-- 用户上传视频转移弹窗 -->
<template>
    <el-dialog title="预览" :visible.sync="show" @close="exit()" width="80%" style="height: 700px;">
        <div style="width: 100%;text-align: left;height: 500px;overflow-y:auto;">
            <div style="width: 50%;float: left;">
                <el-form label-width="150px" style="width:60%;">
                    <el-form-item label="用户ID">
                        <el-input v-model="msg.cust_id" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="标题">
                        <el-input v-model="msg.title"></el-input>
                    </el-form-item>
                    <el-form-item label="简介">
                        <el-input v-model="msg.des" type='textarea' :rows="6"></el-input>
                    </el-form-item>
                    <el-form-item label="视频封面" style="text-align: left;">
                        <upload :src.sync="msg.video_pic"></upload>
                    </el-form-item>
                    <el-form-item label="视频地址" style="text-align: left;">
                        <video id="video1" style="width: 300px;height: 300px;" :src="msg.url" controls></video>
                    </el-form-item>
                </el-form>
            </div>
            <div style="width: 50%;float: left;text-align: left;">
                <p style="margin-top: 0;">
                    转移到分类：
                    <el-select v-model="type" placeholder="请选择分类" style="margin-bottom: 20px;">
                        <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id">
                        </el-option>
                    </el-select>
                </p>
                <!-- 寻医 -->
                <el-form label-width="100px" style="width: 80%;text-align: left;" v-if="type == 2">
                    <el-form-item label="设置排序">
                        <el-input v-model="xysort"></el-input>
                    </el-form-item>
                    <el-form-item label="平台内医生">
                        <div>
                            <el-input v-model="searchdocname" style="width:200px;margin-right: 20px;" clearable
                                @clear="clearsearchdoc" placeholder="请输入医生姓名"></el-input>
                            <el-button type="primary" size="mini" @click="query">查询</el-button>
                        </div>
                        <div>
                            <el-radio-group v-model="doc_id" style="margin-top: 8px;">
                                <el-radio v-for="item in docoptions" :key="item.id" :label="item.id" style="width: 70px;margin-bottom: 5px;">{{item.name}}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                </el-form>
                <!-- 好转病例 -->
                <el-form label-width="100px" v-if="type == 1" style="width: 80%;text-align: left;">
                    <el-form-item label="姓名">
                        <el-input v-model="name"></el-input>
                    </el-form-item>
                    <el-form-item label="年龄">
                        <el-input v-model="age"></el-input>
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-select v-model="sex" placeholder="请选择性别">
                            <el-option v-for="item in sexoptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="平台内疾病">
                        <el-select v-model="ill_lib_id" filterable placeholder="请选择疾病">
                            <el-option v-for="item in illoptions" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="平台内医生" style="margin-bottom: 10px;">
                        <div>
                            <el-input v-model="searchdocname" style="width:200px;margin-right: 20px;" clearable
                                @clear="clearsearchdoc" placeholder="请输入医生姓名"></el-input>
                            <el-button type="primary" size="mini" @click="query">查询</el-button>
                        </div>
                        <div>
                            <el-radio-group v-model="doc_id" style="margin-top: 8px;">
                                <el-radio v-for="item in docoptions" :key="item.id" :label="item.id" style="width: 70px;margin-bottom: 5px;">{{item.name}}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                    <el-form-item label="所患疾病">
                        <el-input v-model="ill"></el-input>
                    </el-form-item>
                    <el-form-item label="排序">
                        <el-input style="float: left;" type="number" v-model="sort" autocomplete="off"
                            placeholder="请输入数字">
                        </el-input>
                        <span style="float: left;color: #909399;width: 100%;text-align: left;">数字越大排名越靠前</span>
                    </el-form-item>
                    <el-form-item label="头像">
                        <upload :src.sync="headimg"></upload>
                    </el-form-item>
                    <el-form-item label="治疗前">
                        <el-input v-model="ill_before" type="textarea" rows="5"></el-input>
                    </el-form-item>
                    <el-form-item label="治疗后">
                        <el-input v-model="ill_after" type="textarea" rows="5"></el-input>
                    </el-form-item>
                    <el-form-item label="治疗简介">
                        <FullEditor id="userupvideocase" rows="10" upload="/"></FullEditor>
                    </el-form-item>
                </el-form>
                <!-- 普通 -->
                <el-form label-width="80px" style="width: 80%;text-align: left;" v-if="type == 3">
                    <el-form-item label="排序">
                        <el-input v-model="sortnum" placeholder="数字越大排名越靠前" autocomplete="off">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div style="text-align: center;">
                    <el-button type="primary" @click="onSubmit">添加</el-button>
                    <el-button @click="del">删除</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
    import upload from '../components/upload'
    import FullEditor from '../components/FullEditor.vue'
    export default {
        props: ['showtc', 'msgid', 'userid', 'videotype'],
        components: {
            upload, FullEditor
        },
        data() {
            return {
                show: false,
                type: 0,
                options: [
                    { id: 2, label: '寻医视频' },
                    { id: 1, label: '反馈视频' },
                    // { id: 3, label: '普通视频' }
                ],
                sexoptions: [
                    { value: '男', label: '男' },
                    { value: '女', lebel: '女' }
                ],
                docoptions: [],
                xysort: 0,
                illoptions: [],
                name: '',
                age: '',
                sex: '',
                ill_lib_id: '',
                ill: '',
                sort: 0,
                headimg: '',
                ill_before: '',
                ill_after: '',
                msg: {},
                id: '',
                sortnum: 0,
                user_id: '',
                searchdocname: '',
                doc_id: ''
            }
        },
        watch: {
            showtc(o, n) {
                this.show = o;
            },
            msgid(o, n) {
                this.id = o;
                this.getdata();
            },
            userid(o, n) {
                this.user_id = o;
                this.axios.get('/gu/get_gu_cust_by_id?id=' + o)
                    .then(res => {
                        console.log(res);
                        if (res.data.code == 0) {
                            this.name = res.data.result.name;
                            this.age = res.data.result.age;
                            this.sex = res.data.result.sex;
                            this.headimg = res.data.result.headimg;
                        }
                    })
            },
            videotype(o, n) {
                this.type = o;
            }
        },
        mounted() {
            this.getill();
        },
        methods: {
            clearsearchdoc() {
                this.searchdocname = '';
                this.docoptions = [];
            },
            query() {
                if (this.searchdocname != '') {
                    this.axios.post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.searchdocname, is_flag: 'all' }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.docoptions = rs.data.result;
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '没有搜索到该医生'
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    this.$message({
                        type: 'error',
                        message: '请输入医生姓名'
                    });
                }
            },
            getdata() {
                this.axios.post('/video/get_gu_video_queue_by_id?id=' + this.id)
                    .then(res => { this.msg = res.data.result });
            },
            htmlEncode: function (html) {
                //1.首先动态创建一个容器标签元素，如DIV
                var temp = document.createElement("div");
                //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
                temp.textContent != undefined
                    ? (temp.textContent = html)
                    : (temp.innerText = html);
                //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
                var output = temp.innerHTML;
                temp = null;
                return output;
            },
            getill() {
                this.axios.get("/gu/get_gu_ill_lib")
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.illoptions = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            exit() {
                this.show = false;
                this.searchdocname = '';
                this.docoptions = [];
                this.doc_id = '';
                this.$emit('update:showtc', false);
                var myVideo = document.getElementById("video1");
                myVideo.pause();
            },
            onSubmit() {
                var url = '', form = {};
                //寻医
                if (this.type == 2) {
                    url = '/xyvideo/insert_gu_xy_video';
                    form.title = this.msg.title;
                    form.url = this.msg.url;
                    form.des = this.msg.des;
                    form.video_pic = this.msg.video_pic;
                    form.sort = this.xysort;
                    form.is_homepage = '1';
                    form.cust_id = this.msg.cust_id;
                    if (this.doc_id != '') {
                        form.doc_id = this.doc_id;
                    } else {
                        form.doc_id = '';
                    }
                }
                //好转病例
                if (this.type == 1) {
                    url = '/gu/insert_gu_showcust';
                    form.name = this.name;
                    form.age = this.age;
                    form.sex = this.sex;
                    form.video = this.msg.url;
                    form.headimg = this.headimg;
                    form.doc_id = this.doc_id;
                    form.ill_lib_id = this.ill_lib_id;
                    form.video_pic = this.msg.video_pic;
                    form.summary = this.msg.des;;
                    form.sort = this.sort;
                    form.ill = this.ill;
                    form.ill_before = this.ill_before;
                    form.ill_after = this.ill_after;
                    let data = tinyMCE.activeEditor.getContent();
                    form.desc = this.htmlEncode(data);
                    form.cust_id = this.msg.cust_id;
                }
                //普通
                if (this.type == 3) {
                    url = '/video/insert_gu_video';
                    form.title = this.msg.title;
                    form.url = this.msg.url;
                    form.des = this.msg.des;
                    form.video_pic = this.msg.video_pic;
                    form.doc_id = '';
                    form.sort = this.sortnum;
                    form.is_homepage = '1';
                }
                if (this.ill_lib_id == '' && this.type == 1) {
                    this.$message({
                        message: '请选择平台内对应疾病',
                        type: 'error'
                    });
                    return;
                }
                if (this.doc_id == '' && this.type == 1) {
                    this.$message({
                        message: '请选择平台内医生',
                        type: 'error'
                    });
                    return;
                }
                console.log(form)
                this.axios.post(url, this.qs.stringify(form))
                    .then(res => {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.searchdocname = '';
                        this.docoptions = [];
                        this.doc_id = '';
                        this.axios.post('/video/update_gu_video_queue_isadd', this.qs.stringify({ is_add: '1', id: this.msg.id, }))
                            .then(res => { console.log(res) });
                        this.$emit('update:showtc', false);
                    })
            },
            del() {
                this.$confirm('此操作将永久删除该视频, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/video/delete_gu_video_queue", this.qs.stringify({ id: this.msg.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.$emit('update:showtc', false);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
    }
</script>